export const psrLookup = {
  1: { name: 'ETH/USD', granularity: '1000000' },
  2: { name: 'BTC/USD', granularity: '1000000' },
  3: { name: 'BNB/USD', granularity: '1000000' },
  4: { name: 'BTC/USD', granularity: '1000000' },
  5: { name: 'ETH/BTC', granularity: '1000000' },
  6: { name: 'BNB/BTC', granularity: '1000000' },
  7: { name: 'BNB/ETH', granularity: '1000000' },
  8: { name: 'ETH/USD', granularity: '1000000' },
  9: { name: 'ETH/USD', granularity: '1000000' },
  10: { name: 'AMPL/USD', granularity: '1000000' },
  11: { name: 'ZEC/ETH', granularity: '1000000' },
  12: { name: 'TRX/ETH', granularity: '1000000' },
  13: { name: 'XRP/USD', granularity: '1000000' },
  14: { name: 'XMR/ETH', granularity: '1000000' },
  15: { name: 'ATOM/USD', granularity: '1000000' },
  16: { name: 'LTC/USD', granularity: '1000000' },
  17: { name: 'WAVES/BTC', granularity: '1000000' },
  18: { name: 'REP/BTC', granularity: '1000000' },
  19: { name: 'TUSD/ETH', granularity: '1000000' },
  20: { name: 'EOS/USD', granularity: '1000000' },
  21: { name: 'IOTA/USD', granularity: '1000000' },
  22: { name: 'ETC/USD', granularity: '1000000' },
  23: { name: 'ETH/PAX', granularity: '1000000' },
  24: { name: 'ETH/BTC', granularity: '1000000' },
  25: { name: 'USDC/USDT', granularity: '1000000' },
  26: { name: 'XTZ/USD', granularity: '1000000' },
  27: { name: 'LINK/USD', granularity: '1000000' },
  28: { name: 'ZRX/BNB', granularity: '1000000' },
  29: { name: 'ZEC/USD', granularity: '1000000' },
  30: { name: 'XAU/USD', granularity: '1000000' },
  31: { name: 'MATIC/USD', granularity: '1000000' },
  32: { name: 'BAT/USD', granularity: '1000000' },
  33: { name: 'ALGO/USD', granularity: '1000000' },
  34: { name: 'ZRX/USD', granularity: '1000000' },
  35: { name: 'COS/USD', granularity: '1000000' },
  36: { name: 'BCH/USD', granularity: '1000000' },
  37: { name: 'REP/USD', granularity: '1000000' },
  38: { name: 'GNO/USD', granularity: '1000000' },
  39: { name: 'DAI/USD', granularity: '1000000' },
  40: { name: 'STEEM/BTCUPCE', granularity: '1000000' },
  41: { name: 'USPCE', granularity: '1000000' },
  42: { name: 'BTC/USD', granularity: '1000000' },
  43: { name: 'STT/ETH', granularity: '1000000' },
  44: { name: 'BTC/USD', granularity: '1000000' },
  45: { name: 'STT/USD', granularity: '1000000' },
  46: { name: 'ETH/USD', granularity: '1000000' },
  47: { name: 'BSV/USD', granularity: '1000000' },
  48: { name: 'MAKER/USD', granularity: '1000000' },
  49: { name: 'BCH/USD', granularity: '1000000' },
  50: { name: 'STT/USD', granularity: '1000000' },
  51: { name: 'XMR/USD', granularity: '1000000' },
  52: { name: 'XFT/USD', granularity: '1000000' },
  53: { name: 'BTCDominance', granularity: '1000000' },
  54: { name: 'WAVES/USD', granularity: '1000000' },
  55: { name: 'OGN/USD', granularity: '1000000' },
  56: { name: 'VIXEOD', granularity: '1000000' },
  57: { name: 'DEFITVL', granularity: '1000000' },
  58: { name: 'DEFICAP', granularity: '1000000' },
  59: { name: 'ETH/JPY', granularity: '1000000' },
}
